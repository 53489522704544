<template>
  <MenuComponent menu-selector="#kt-search-menu">
    <template v-slot:toggle>
      <div
        id="kt_header_search"
        class="header-search d-flex align-items-stretch"
        data-kt-menu-target="#kt-search-menu"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <div class="d-flex align-items-center" id="kt_header_search_toggle">
          <div
            class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
          >
            <i class="bi bi-search fs-2"/>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div
        class="menu menu-sub menu-sub-dropdown menu-column p-7 w-700px w-md-700px"
        data-kt-menu="true"
        id="kt-search-menu"
      >
        <div>
          <div class="w-100 position-relative mb-3">
            <i class="bi bi-search fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-0"></i>

            <input
              ref="inputRef"
              v-model="state.search"
              @keydown="e => {searching(e.key)}"
              type="text"
              class="form-control form-control-flush ps-10"
              name="search"
              :placeholder="$t('Rechercher...')"
            />

            <span
              v-if="loading"
              class="position-absolute top-50 end-0 translate-middle-y lh-0 me-1"
            >
              <span
                class="spinner-border h-15px w-15px align-middle text-gray-400"
              ></span>
            </span>

            <span
              v-show="state.search.length && !loading"
              @click="reset()"
              class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0"
            >
              <i class="bi bi-x fs-2 fs-lg-1 me-0"></i>
            </span>

            <div class="position-absolute top-50 end-0 translate-middle-y">
              <div
                v-if="!state.search && !loading"
                @click="state.mode = 'preferences'"
                class="btn btn-icon w-20px btn-sm btn-active-color-primary me-1"
                data-bs-toggle="tooltip"
                title="Afficher les préférences"
              >
                <i class="bi bi-gear fs-2"></i>
              </div>

              <div
                v-if="!state.search && !loading"
                @click="state.mode = 'advanced-options'"
                class="btn btn-icon w-20px btn-sm btn-active-color-primary d-none"
                data-bs-toggle="tooltip"
                title="Afficher plus d'options"
              >
                <i class="bi bi-chevron-down fs-2"></i>
              </div>
            </div>
          </div>

          <div class="separator border-gray-200 mb-6"></div>
          <div v-if="state.mode === 'results'" class="scroll-y affix-container" style="max-height: calc(90svh - 100px);">
            <div class="d-flex justify-content-between border-bottom h-30px">
              <h4 class="text-dark text-end fs-5">{{ state.displayCount }} {{$t("résultats")}}</h4>
              <p v-if="state.countResults > 50 && !state.showAll" @click="showAll" class="text-primary text-end fs-6 cursor-pointer">{{$t("Cliquer ici pour afficher les")}} {{ state.countResults }} {{$t("résultats")}}</p>
            </div>
            <div v-for="item, index in state.resultSearch" :key="index" class="cursor-pointer ">
              <el-affix target=".affix-container" :offset="80">
                <h3 v-if="state.resultSearch.findIndex(result => result.sea_search_type === item.sea_search_type) === index" class="fs-5 text-primary m-0 mt-3 gradientpls">{{$t(item.sea_search_type)}}</h3>
              </el-affix>
              <a
                :href="getLink(item)"
                class="d-flex text-dark text-hover-primary align-items-center p-5 bg-hover-light-primary"
              >
                <div class="symbol symbol-40px symbol-circle me-4"></div>
                <div class="d-flex flex-column justify-content-start fw-semobold mw-100">
                  <span class="fs-5 fw-semobold text-primary opacity-75">{{ item.sea_title }}</span>
                  <span class="fs-7 fw-light text-dark" style="white-space: pre-wrap;" v-html="item.sea_response.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
                </div>
              </a>
              <div class="separator border-gray-200" v-if="state.resultSearch.findLastIndex(element => element.sea_search_type == item.sea_search_type) !== index"></div>
            </div>
          </div>

          <p v-else-if="state.mode === 'main' && state.recentSearchs.length == 0" class="text-center text-gray-400 m-0">{{$t("Recherchez et appuyez sur Entrée")}}</p>
          <div v-else-if="state.mode === 'main' && state.recentSearchs.length > 0" class="scroll-y" style="max-height: calc(90svh - 100px);">
            <h3 class="text-primary pb-3 fs-5">{{$t("Vos recherches récentes")}}</h3>            
            <div v-for="item, index in state.recentSearchs" :key="index" 
              @click="e => {clickRecent(e, item.search)}" 
              :class="{'border-bottom': state.recentSearchs.length-1 !== index}"
              class="d-flex align-items-center justify-content-between cursor-pointer bg-hover-light-primary py-5 w-100 px-0">
              <p  
                class="fs-6 m-0 ps-2 fw-bolder text-break">
                {{ item.search }}
              </p>
              <p class="m-0 pe-2 text-muted">{{ getDate(item.date) }}</p>
            </div>
          </div>

          <div v-else-if="state.mode === 'empty'" class="text-center">
            <div class="pt-10 pb-10">
              <KTIcon icon-name="search-list" icon-class="fs-4x opacity-50" />
            </div>

            <div class="pb-15 fw-semobold">
              <h3 class="text-gray-600 fs-5 mb-2">{{$t("Aucun résultat")}}</h3>                
            </div>
          </div>
        </div>

        <!-- <div v-if="state.mode === 'advanced-options'" class="pt-1">
          <h3 class="fw-semobold text-dark mb-7">Recherche avancée</h3>

          <div class="mb-5">
            <input
              type="text"
              class="form-control form-control-sm form-control-solid"
              placeholder="Contains the word"
              name="query"
            />
          </div>

          <div class="mb-5">
            <div class="nav-group nav-group-fluid">
              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="type"
                  value="has"
                  checked
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary"
                >
                  Tous
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="type"
                  value="users"
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
                >
                  Users
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="type"
                  value="orders"
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
                >
                  Orders
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="type"
                  value="projects"
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
                >
                  Projects
                </span>
              </label>
            </div>
          </div>

          <div class="mb-5">
            <input
              type="text"
              name="assignedto"
              class="form-control form-control-sm form-control-solid"
              placeholder="Assigned to"
              value=""
            />
          </div>

          <div class="mb-5">
            <input
              type="text"
              name="collaborators"
              class="form-control form-control-sm form-control-solid"
              placeholder="Collaborators"
              value=""
            />
          </div>

          <div class="mb-5">
            <div class="nav-group nav-group-fluid">
              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="attachment"
                  value="has"
                  checked
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary"
                >
                  Has attachment
                </span>
              </label>

              <label>
                <input
                  type="radio"
                  class="btn-check"
                  name="attachment"
                  value="any"
                />
                <span
                  class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
                >
                  Any
                </span>
              </label>
            </div>
          </div>

          <div class="mb-5">
            <select
              name="timezone"
              aria-label="Select a Timezone"
              data-control="select2"
              data-placeholder="date_period"
              class="form-select form-select-sm form-select-solid"
            >
              <option value="next">Within the next</option>
              <option value="last">Within the last</option>
              <option value="between">Between</option>
              <option value="on">On</option>
            </select>
          </div>

          <div class="row mb-8">
            <div class="col-6">
              <input
                type="number"
                name="date_number"
                class="form-control form-control-sm form-control-solid"
                placeholder="Lenght"
                value=""
              />
            </div>

            <div class="col-6">
              <select
                name="date_typer"
                aria-label="Select a Timezone"
                data-control="select2"
                data-placeholder="Period"
                class="form-select form-select-sm form-select-solid"
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button
              @click="state.mode = 'main'"
              class="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
            >
              Cancel
            </button>

            <a href="#" class="btn btn-sm fw-bold btn-primary">Search</a>
          </div>
        </div> -->

        <div v-if="state.mode === 'preferences'" class="pt-1">
          <h3 class="fw-semobold text-dark mb-7">{{$t("Préférences")}}</h3>

          <div class="pb-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Offres commerciales")}}
              </span>

              <input
                v-model="state.preferences.Offres"
                @change="setParamInLocal"
                class="form-check-input"
                type="checkbox"
                checked
              />
            </label>
          </div>

          <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Commandes")}}
              </span>
              <input
                class="form-check-input"
                v-model="state.preferences.Commandes"
                @change="setParamInLocal"
                type="checkbox"
                checked
              />
            </label>
          </div>

          <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Factures")}}
              </span>
              <input
                v-model="state.preferences.Factures"
                @change="setParamInLocal"
                class="form-check-input"
                type="checkbox" />
            </label>
          </div>

          <!-- <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                Équipement sous contrat
              </span>
              <input
                class="form-check-input"
                v-model="state.preferences.Equipement"
                @change="setParamInLocal"
                type="checkbox"
                checked
              />
            </label>
          </div> -->

          <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Tickets")}}
              </span>
              <input
                class="form-check-input"
                v-model="state.preferences.Tickets"
                @change="setParamInLocal"
                type="checkbox"
                checked
              />
            </label>
          </div>

          <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Stock")}}
              </span>
              <input
                class="form-check-input"
                v-model="state.preferences.Stock"
                @change="setParamInLocal"
                type="checkbox"
                checked
              />
            </label>
          </div>

          <div class="py-4 border-bottom">
            <label
              class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack"
            >
              <span
                class="form-check-label text-gray-700 fs-6 fw-semobold ms-0 me-2"
              >
                {{$t("Sites")}}
              </span>
              <input
                class="form-check-input" 
                v-model="state.preferences.Sites"
                @change="setParamInLocal"
                type="checkbox" 
              />
            </label>
          </div>

          <div class="d-flex justify-content-end pt-7">
            <div
              @click="e => {e.stopPropagation(); state.mode = 'main'}"
              class="btn btn-sm btn-primary fw-bold"
            >
              {{$t("Retour")}}
            </div>
          </div>
        </div>
      </div>
    </template>
  </MenuComponent>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import * as moment from "moment"
import MenuComponent from "@/components/menu/MenuComponent.vue";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "global-search",
  components: {
    MenuComponent,
  },
  setup() {
    const loading = ref<boolean>(false);
    const inputRef = ref<HTMLInputElement | null>(null);

    const store = useStore()
    const router = useRouter()
    const state = reactive({
      loaderEnabled: true,
      search: "",
      myList: [] as any,
      mode: "main",
      recentSearchs: [] as any,
      preferences: {} as any,
      resultSearch: [] as any,
      searchToRender: "",
      countResults: 0,
      displayCount: 0,
      showAll: false
    });

    const { t } = useI18n()

    const getLink = (item) => {
      const routeName = router.currentRoute.value.name
      console.log(routeName)
      switch (item.sea_search_type) {
        case "Offres":
          if(routeName == 'Offre commerciale') return "#/loader/Offre commerciale/code/" + item.sea_link;
          return '#/stillboard/offres/' + item.sea_link
        case "Commandes":
          if(routeName == 'Commande') return "#/loader/Commande/code/" + item.sea_seq;
          return '#/stillboard/commandes/' + item.sea_seq
        case "Factures":
          if(routeName == 'Facture') return "#/loader/Facture/code/" + item.sea_link;
          return '#/stillboard/factures/' + item.sea_link
        case "Tickets":
          if(routeName == 'ticket' || routeName == 'ticketWid') return "#/loader/ticketWid/ticid/" + item.sea_seq;
          return '#/stilldeploy/ticket/' + item.sea_seq
        case "Stock":
          if(routeName == 'stock') return "#/loader/stock/code/" + item.sea_link;
          return '#/stilldeploy/stock/' + item.sea_link
        case "Sites":
          if(routeName == 'sites') return "#/loader/sites/code/" + item.sea_link;
          return '#/stilldeploy/sites/' + item.sea_link
        default:
          break;
      }
    }

    const searching = async(key, fromRecent=false) => {
      if (key == "Enter") {
        if (state.search.length < 3) {
          ElNotification({
            title: t("Erreur"),
            message: t("La recherche doit contenir au moins 3 caractères."),
            type: 'error',
          })
          return;
        }
        if (Object.values(state.preferences).every(value => !value)) {
          ElNotification({
            title: t("Erreur"),
            message: t("Veuillez activer au moins un paramètre."),
            type: 'error',
          })
          return;
        }
        const datas = {
          preferences: state.preferences,
          search: state.search
        }
        loading.value = true;
        const searchedData = await mAxiosApi.post("getGlobalSearch", datas);
        state.showAll = false
        state.countResults = searchedData.data.total
        state.displayCount = state.countResults > 50 ? 50 : state.countResults
        state.searchToRender = state.search
        searchedData.data.results.sort((a,b) => {
          if (a.sea_search_type < b.sea_search_type) {
              return -1;
          }
          if (a.sea_search_type > b.sea_search_type) {
              return 1;
          }
          return 0;
        });
        state.resultSearch = searchedData.data.results;

        if (state.resultSearch.length == 0) {
          state.mode = "empty";
        }else{
          state.mode = "results";
        }
        
        if (!fromRecent) {
          state.recentSearchs?.length >= 15 && state.recentSearchs.pop();
          state.recentSearchs.unshift({
            search: state.search,
            date: new Date(),
          });

          localStorage.setItem("recentSearchs", JSON.stringify(state.recentSearchs))
        }
        loading.value = false;
        
      }else{
        return false
      }
    };

    const clickRecent = (e, item) => {
      e.stopPropagation();
      state.search = item;
      searching('Enter', true)
    }

    onMounted(() => {
      if (!localStorage.getItem("recentSearchs") || typeof JSON.parse(localStorage.getItem("recentSearchs") + '')[0] === 'string') {
        localStorage.setItem("recentSearchs", "[]");
        state.recentSearchs = [];
      }else{
        state.recentSearchs = JSON.parse(localStorage.getItem("recentSearchs") as any)
      }

      if (!localStorage.getItem("globalSearchParams")) {
        const baseParams = {
          Offres: true, 
          Commandes: true, 
          Factures: true, 
          Equipement: true, 
          Tickets: true, 
          Stock: true, 
          Sites: true
        }
        localStorage.setItem("globalSearchParams", JSON.stringify(baseParams));
        state.preferences = baseParams;
      }else{
        state.preferences = JSON.parse(localStorage.getItem("globalSearchParams") as any)
      }
      console.log(state.preferences)
    })

    const reset = () => {
      state.search = "";
      state.mode = "main";
    };

    const getDate = (d) => {
      return moment.default(d).format("DD/MM/YYYY")
    }

    const setParamInLocal = () => {
      console.log(JSON.stringify(state.preferences))
      localStorage.setItem("globalSearchParams", JSON.stringify(state.preferences))
    }

    const showAll = async() => {
      const datas = {
        preferences: state.preferences,
        showAll: true,
        search: state.searchToRender
      }
      loading.value = true;
      const searchedData = await mAxiosApi.post("getGlobalSearch", datas);
      state.displayCount = state.countResults;
      state.resultSearch = searchedData.data.results;
      state.showAll = true;
      loading.value = false;
    }

    return {
      state,
      loading,
      searching,
      clickRecent,
      reset,
      inputRef,
      getLink,
      getDate,
      setParamInLocal,
      showAll
    };
  },
});
</script>