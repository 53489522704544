
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import * as moment from "moment";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n()
    const frNav = ["fr", "fr-FR", "fr-BE", "fr-CA", "fr-LU", "fr-CH", "fr-MC", "fr-MC"]

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      role : store.getters.currentUser.con_role_web,
      language: getLanguage()
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;
      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const signOut = () => {
      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const changeLang = () => {
      let newLang
      if (state.language === "fr") {
        newLang = "en"
      }else{
        newLang = "fr"
      }
      localStorage.setItem("language", newLang)
      i18n.locale.value = newLang
      moment.default.locale(newLang)
      setLanguageBack(newLang)
      state.language = newLang
    }

    function getLanguage() {
      if (localStorage.getItem("language")) {
        return localStorage.getItem("language")
      }else{
        if (frNav.indexOf(navigator.language) !== -1) {
          return "fr"
        }else{
          return "en"
        }
      }
    }

    async function setLanguageBack(language): Promise<any> {
      const response = await mAxiosApi.get("/setLanguage/" + language);
    }

    return {
      signOut,
      state,
      changeLang,
    };
  },
});
