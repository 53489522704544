
import { defineComponent, watch, reactive } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import changeSociete from "@/views/still/changeSociete.vue";
import { useStore } from "vuex";
import GlobalSearchs from "@/views/still/GlobalSearchs.vue";
export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    changeSociete,
    GlobalSearchs
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }
    return {
      state,
      reloadRouter,
    };
  },
});
