<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <div
        class="
          btn btn-icon btn-active-light-primary
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        id="kt_activities_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/general/gen032.svg" />
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <div
        class="
          btn btn-icon btn-active-light-primary
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/general/gen025.svg" />
        </span>
      </div>
      <KTQuickLinksMenu></KTQuickLinksMenu>
    </div>
    -->
    <GlobalSearchs></GlobalSearchs>
    <div class="d-flex align-items-center ms-1 ms-lg-3">
        <a
          class="
            btn btn-icon btn-active-light-primary
            position-relative
            w-150px
            h-30px
            w-md-150px
            h-md-40px
          "
          href="https://stillonstock.com/#/sign-in" target="_BLANK"
        >
          <span class="svg-icon svg-icon-1">
            <b class="fs-8">{{$t("Accèder à ")}}</b><br><div style="color: #007ab5" class="mt-1">StillOnStock</div>
          </span>

          <span
            class="
              bullet bullet-dot
              bg-primary
              h-6px
              w-6px
              position-absolute
              translate-middle
              top-0
              start-50
              animation-blink
            "
          >
          </span>
        </a>
    </div>
    
    <changeSociete  @reloadRouter="reloadRouter($event)"/>
    
    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <div class="symbol-label fs-2 fw-bold text-primary">
          {{ state.initialContact }}
        </div>
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, watch, reactive } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import changeSociete from "@/views/still/changeSociete.vue";
import { useStore } from "vuex";
import GlobalSearchs from "@/views/still/GlobalSearchs.vue";
export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    changeSociete,
    GlobalSearchs
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }
    return {
      state,
      reloadRouter,
    };
  },
});
</script>
