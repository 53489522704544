
import { defineComponent, onMounted, reactive, ref } from "vue";
import * as moment from "moment"
import MenuComponent from "@/components/menu/MenuComponent.vue";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "global-search",
  components: {
    MenuComponent,
  },
  setup() {
    const loading = ref<boolean>(false);
    const inputRef = ref<HTMLInputElement | null>(null);

    const store = useStore()
    const router = useRouter()
    const state = reactive({
      loaderEnabled: true,
      search: "",
      myList: [] as any,
      mode: "main",
      recentSearchs: [] as any,
      preferences: {} as any,
      resultSearch: [] as any,
      searchToRender: "",
      countResults: 0,
      displayCount: 0,
      showAll: false
    });

    const { t } = useI18n()

    const getLink = (item) => {
      const routeName = router.currentRoute.value.name
      console.log(routeName)
      switch (item.sea_search_type) {
        case "Offres":
          if(routeName == 'Offre commerciale') return "#/loader/Offre commerciale/code/" + item.sea_link;
          return '#/stillboard/offres/' + item.sea_link
        case "Commandes":
          if(routeName == 'Commande') return "#/loader/Commande/code/" + item.sea_seq;
          return '#/stillboard/commandes/' + item.sea_seq
        case "Factures":
          if(routeName == 'Facture') return "#/loader/Facture/code/" + item.sea_link;
          return '#/stillboard/factures/' + item.sea_link
        case "Tickets":
          if(routeName == 'ticket' || routeName == 'ticketWid') return "#/loader/ticketWid/ticid/" + item.sea_seq;
          return '#/stilldeploy/ticket/' + item.sea_seq
        case "Stock":
          if(routeName == 'stock') return "#/loader/stock/code/" + item.sea_link;
          return '#/stilldeploy/stock/' + item.sea_link
        case "Sites":
          if(routeName == 'sites') return "#/loader/sites/code/" + item.sea_link;
          return '#/stilldeploy/sites/' + item.sea_link
        default:
          break;
      }
    }

    const searching = async(key, fromRecent=false) => {
      if (key == "Enter") {
        if (state.search.length < 3) {
          ElNotification({
            title: t("Erreur"),
            message: t("La recherche doit contenir au moins 3 caractères."),
            type: 'error',
          })
          return;
        }
        if (Object.values(state.preferences).every(value => !value)) {
          ElNotification({
            title: t("Erreur"),
            message: t("Veuillez activer au moins un paramètre."),
            type: 'error',
          })
          return;
        }
        const datas = {
          preferences: state.preferences,
          search: state.search
        }
        loading.value = true;
        const searchedData = await mAxiosApi.post("getGlobalSearch", datas);
        state.showAll = false
        state.countResults = searchedData.data.total
        state.displayCount = state.countResults > 50 ? 50 : state.countResults
        state.searchToRender = state.search
        searchedData.data.results.sort((a,b) => {
          if (a.sea_search_type < b.sea_search_type) {
              return -1;
          }
          if (a.sea_search_type > b.sea_search_type) {
              return 1;
          }
          return 0;
        });
        state.resultSearch = searchedData.data.results;

        if (state.resultSearch.length == 0) {
          state.mode = "empty";
        }else{
          state.mode = "results";
        }
        
        if (!fromRecent) {
          state.recentSearchs?.length >= 15 && state.recentSearchs.pop();
          state.recentSearchs.unshift({
            search: state.search,
            date: new Date(),
          });

          localStorage.setItem("recentSearchs", JSON.stringify(state.recentSearchs))
        }
        loading.value = false;
        
      }else{
        return false
      }
    };

    const clickRecent = (e, item) => {
      e.stopPropagation();
      state.search = item;
      searching('Enter', true)
    }

    onMounted(() => {
      if (!localStorage.getItem("recentSearchs") || typeof JSON.parse(localStorage.getItem("recentSearchs") + '')[0] === 'string') {
        localStorage.setItem("recentSearchs", "[]");
        state.recentSearchs = [];
      }else{
        state.recentSearchs = JSON.parse(localStorage.getItem("recentSearchs") as any)
      }

      if (!localStorage.getItem("globalSearchParams")) {
        const baseParams = {
          Offres: true, 
          Commandes: true, 
          Factures: true, 
          Equipement: true, 
          Tickets: true, 
          Stock: true, 
          Sites: true
        }
        localStorage.setItem("globalSearchParams", JSON.stringify(baseParams));
        state.preferences = baseParams;
      }else{
        state.preferences = JSON.parse(localStorage.getItem("globalSearchParams") as any)
      }
      console.log(state.preferences)
    })

    const reset = () => {
      state.search = "";
      state.mode = "main";
    };

    const getDate = (d) => {
      return moment.default(d).format("DD/MM/YYYY")
    }

    const setParamInLocal = () => {
      console.log(JSON.stringify(state.preferences))
      localStorage.setItem("globalSearchParams", JSON.stringify(state.preferences))
    }

    const showAll = async() => {
      const datas = {
        preferences: state.preferences,
        showAll: true,
        search: state.searchToRender
      }
      loading.value = true;
      const searchedData = await mAxiosApi.post("getGlobalSearch", datas);
      state.displayCount = state.countResults;
      state.resultSearch = searchedData.data.results;
      state.showAll = true;
      loading.value = false;
    }

    return {
      state,
      loading,
      searching,
      clickRecent,
      reset,
      inputRef,
      getLink,
      getDate,
      setParamInLocal,
      showAll
    };
  },
});
